import React, { useState, useEffect, useCallback } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import styles from './modules/Admin.module.css';
import { supabase } from '../supabase'; 

export default function AdminPage() {
  const [selectedRoom, setSelectedRoom] = useState('');
  const [selectedCourtType, setSelectedCourtType] = useState('');
  const [caseNumber, setCaseNumber] = useState('');
  const [error, setError] = useState('');
  const [navigateToSession, setNavigateToSession] = useState(false);
  const [sessionId, setSessionId] = useState(null);
  const navigate = useNavigate();

  const rooms = [
    'ΑΙΘΟΥΣΑ 1',
    'ΑΙΘΟΥΣΑ 2',
    'ΑΙΘΟΥΣΑ 3',
    'ΑΙΘΟΥΣΑ 4',
  ];
  
  const courtTypes = [
    'ΜΟΝΟΜΕΛΕΣ ΠΛΗΜΜΕΛΕΙΟΔΙΚΕΙΟ',
    'Α ΤΡΙΜΕΛΕΣ ΠΛΗΜΜΕΛΕΙΟΔΙΚΕΙΟ',
    'Β ΤΡΙΜΕΛΕΣ ΠΛΗΜΜΕΛΕΙΟΔΙΚΕΙΟ',
    'ΜΟΝΟΜΕΛΕΣ ΕΦΕΤΕΙΟ ΚΑΚΟΥΡΓΗΜΑΤΩΝ',
    'ΤΡΙΜΕΛΕΣ ΕΦΕΤΕΙΟ ΚΑΚΟΥΡΓΗΜΑΤΩΝ',
    'ΤΡΙΜΕΛΕΣ ΕΦΕΤΕΙΟ ΠΛΗΜΜΕΛΗΜΑΤΩΝ',
    'ΜΙΚΤΟ ΟΡΚΩΤΟ ΔΙΚΑΣΤΗΡΙΟ',
    'ΜΙΚΤΟ ΟΡΚΩΤΟ ΕΦΕΤΕΙΟ',
  ];
  
  const [activeRooms, setActiveRooms] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedRoomToDelete, setSelectedRoomToDelete] = useState(null);

  const fetchActiveRooms = useCallback(async () => {
    const { data, error } = await supabase
      .from('rooms')
      .select('room_name, current_session_id')
      .not('current_session_id', 'is', null);

    if (error) {
      console.error('Σφάλμα κατά τη λήψη ενεργών αιθουσών:', error);
    } else {
      setActiveRooms(data);
    }
  }, []);

  const handleRoomsChange = useCallback((payload) => {
    console.log('Αλλαγή στον πίνακα rooms:', payload);
    fetchActiveRooms();
  }, [fetchActiveRooms]);

  useEffect(() => {
    const fetchCurrentSession = async () => {
      const { data, error } = await supabase
        .from('sessions')
        .select('session_id')
        .order('created_at', { ascending: false });

      if (error) {
        console.error('Error fetching session:', error);
      } else {
        const activeSession = data.find(session => session.status === 'live');
        setSessionId(activeSession ? activeSession.session_id : null);
      }
    };
    fetchCurrentSession();
    fetchActiveRooms();

    const roomsSubscription = supabase
      .channel('rooms_channel')
      .on('postgres_changes', { event: '*', schema: 'public', table: 'rooms' }, handleRoomsChange)
      .subscribe();

    return () => {
      supabase.removeChannel(roomsSubscription);
    };
  }, [fetchActiveRooms, handleRoomsChange]);

  const handleRoomChange = (event) => {
    setSelectedRoom(event.target.value);
  };
  
  const handleCourtTypeChange = (event) => {
    setSelectedCourtType(event.target.value);
  };
  
  const handleCaseNumberChange = (event) => {
    const value = event.target.value;
    if (value === '' || (parseInt(value) >= 1 && parseInt(value) <= 135)) {
      setCaseNumber(value);
      setError('');
    } else {
      setError('Ο αριθμός υποθέσεων πρέπει να είναι μεταξύ 1 και 135.');
    }
  };

  const handleSubmit = async () => {
    if (selectedCourtType && caseNumber && selectedRoom) {
      const { data: activeSession } = await supabase
        .from('rooms')
        .select('current_session_id')
        .eq('room_name', selectedRoom)
        .single();
      if (activeSession && activeSession.current_session_id) {
        const { data: sessionData } = await supabase
          .from('sessions')
          .select('status')
          .eq('session_id', activeSession.current_session_id)
          .single();

        if (sessionData && sessionData.status === 'live') {
          setError('Υπάρχει ήδη ενεργή συνεδρία σε αυτή την αίθουσα.');
          return;
        }
      }
      let newSessionId;

      const { data: sessions } = await supabase
        .from('sessions')
        .select('session_id')
        .order('session_id', { ascending: false })
        .limit(1);

      newSessionId = sessions.length > 0 ? sessions[0].session_id + 1 : 1; 
      setSessionId(newSessionId); 

      const { error: sessionError } = await supabase
        .from('sessions')
        .insert([{ session_id: newSessionId, status: 'live' }]); 

      if (sessionError) {
        setError('Αποτυχία δημιουργίας νέου session: ' + sessionError.message);
        return;
      }
      const { error: roomUpdateError } = await supabase
      .from('rooms')
      .update({ current_session_id: newSessionId })
      .eq('room_name', selectedRoom); 
    if (roomUpdateError) {
      setError('Αποτυχία ενημέρωσης αίθουσας: ' + roomUpdateError.message);
      return;
    }
      const casePromises = [];
      for (let i = 1; i <= parseInt(caseNumber); i++) {
        casePromises.push(
          supabase.from('cases').insert([{
            case_number: i,
            court_type: selectedCourtType,
            room: selectedRoom,
            session_id: newSessionId,
          }])
        );
      }
      await Promise.all(casePromises);
      setNavigateToSession(true);
    } else {
      setError('Παρακαλώ συμπληρώστε όλα τα πεδία.');
    }
  };
  const handleLogout = async () => {
    try {
      const { error } = await supabase.auth.signOut(); 
      if (error) {
        setError('Προέκυψε σφάλμα κατά την αποσύνδεση: ' + error.message);
      } else {
        navigate('/');
      }
    } catch (error) {
      setError('Προέκυψε σφάλμα κατά την αποσύνδεση: ' + error.message);
    }
  };
  if (navigateToSession && sessionId) {
    const greeklishRoom = selectedRoom
      .replace('ΑΙΘΟΥΣΑ', 'Aithousa')
      .replace(/ /g, '-');
    const greeklishCourtType = selectedCourtType
      .replace('ΜΟΝΟΜΕΛΕΣ', 'Monomeles')
      .replace('ΤΡΙΜΕΛΕΣ', 'Trimeles')
      .replace('ΠΛΗΜΜΕΛΕΙΟΔΙΚΕΙΟ', 'Plimeleiodikeio')
      .replace('ΕΦΕΤΕΙΟ', 'Efeteio')
      .replace('ΚΑΚΟΥΡΓΗΜΑΤΩΝ', 'Kakourgimatwn')
      .replace('ΠΛΗΜΜΕΛΗΜΑΤΩΝ', 'Plimmelimatwn')
      .replace('ΜΙΚΤΟ ΟΡΚΩΤΟ ΔΙΚΑΣΤΗΡΙΟ', 'Mikto-Orkoto-Dikastirio')
      .replace('ΜΙΚΤΟ ΟΡΚΩΤΟ ΕΦΕΤΕΙΟ', 'Miktro-Orkoto-Efeteio')
      .replace(/ /g, '-');
    return (
      <Navigate 
        to={`/session/${sessionId}/${greeklishRoom}/${greeklishCourtType}/${parseInt(caseNumber)}`}
        state={{ 
          room: selectedRoom, 
          courtType: selectedCourtType, 
          caseNumber: parseInt(caseNumber) 
        }}
      />
    );
  }
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); 
      handleSubmit(); 
    }
  };
  const handleRoomClick = (room) => {
    setSelectedRoomToDelete(room);
    setShowModal(true);
  };

  const handleDeleteRoom = async () => {
    if (selectedRoomToDelete) {
      const { data: roomData, error: roomError } = await supabase
        .from('rooms')
        .select('current_session_id')
        .eq('room_name', selectedRoomToDelete)
        .single();

      if (roomError) {
        setError('Σφάλμα κατά την ανάκτηση πληροφοριών αίθουσας: ' + roomError.message);
        setShowModal(false);
        return;
      }

      const sessionIdToDelete = roomData.current_session_id;

      if (sessionIdToDelete) {
        const { error: casesDeleteError } = await supabase
          .from('cases')
          .delete()
          .eq('session_id', sessionIdToDelete);

        if (casesDeleteError) {
          setError('Αποτυχία διαγραφής υποθέσεων: ' + casesDeleteError.message);
          setShowModal(false);
          return;
        }
        const { error: roomUpdateError } = await supabase
          .from('rooms')
          .update({ current_session_id: null })
          .eq('room_name', selectedRoomToDelete);

        if (roomUpdateError) {
          setError('Αποτυχία κατάργησης αίθουσας: ' + roomUpdateError.message);
          setShowModal(false);
          return;
        }
        const { error: sessionDeleteError } = await supabase
          .from('sessions')
          .delete()
          .eq('session_id', sessionIdToDelete);

        if (sessionDeleteError) {
          setError('Αποτυχία διαγραφής συνεδρίας: ' + sessionDeleteError.message);
          setShowModal(false);
          return;
        }
      }
      fetchActiveRooms();
    }
    setShowModal(false);
  };

  return (
    <div className={styles.outerContainer}>
      <div className={styles.container}>
        <div className={styles.header}>
          <img src="../images/dsh-icon.svg" alt="icon" className={styles.icon} />
          <h1 className={styles.title}>ΔΙΑΧΕΙΡΙΣΗ ΥΠΟΘΕΣΕΩΝ</h1>
        </div>
        <hr className={styles.hr} />
        {activeRooms.length > 0 && (
          <div className={styles.activeRooms}>
            <p>ΕΝΕΡΓΕΣ ΑΙΘΟΥΣΕΣ</p>
            <div className={styles.roomButtons}>
              {activeRooms.map((room) => (
                <button
                  key={room.room_name}
                  type="button"
                  className={styles.roomButton}
                  onClick={() => handleRoomClick(room.room_name)}
                >
                  {room.room_name.replace('ΑΙΘΟΥΣΑ ', '')}
                </button>
              ))}
            </div>
          </div>
        )}
        {showModal && (
          <div className={styles.modal}>
            <div className={styles.modalContent}>
              <p>ΚΑΤΑΡΓΗΣΗ {selectedRoomToDelete};</p>
              <div className={styles.modalButtons}>
                <button  onClick={handleDeleteRoom}>ΝΑΙ</button>
                <button onClick={() => setShowModal(false)}>ΟΧΙ</button>
              </div>
            </div>
          </div>
        )}
        <form className={styles.form} onKeyDown={handleKeyDown}>
          <div className={styles.rooms}>
            <select id={styles.roomDown} value={selectedRoom} onChange={handleRoomChange} className={styles.selections}>
              <option value="">Επιλέξτε αίθουσα</option>
              {rooms.map((room, index) => (
                <option key={index} value={room}> 
                  {room}
                </option>
              ))}
            </select>
          </div>  
          <div className={styles.selections}>
            <select id="courtTypeDropdown" value={selectedCourtType} onChange={handleCourtTypeChange} className={styles.selected}>
              <option value="">Επιλέξτε τύπο δικαστηρίου</option>
              {courtTypes.map((courtType, index) => (
                <option key={index} value={courtType}>
                  {courtType}
                </option>
              ))}
            </select>
          </div>
          <div className={styles.cases}>
            <input
              id={styles.inputbox}
              type="number"
              value={caseNumber}
              onChange={handleCaseNumberChange}
              min="1"
              max="135"
              placeholder='Επιλέξτε αριθμό υποθέσεων'
            />
            {error && <p className={styles.error}>{error}</p>}
          </div>
          <button type="button" onClick={handleSubmit} id={styles.button}>ΔΗΜΙΟΥΡΓΙΑ ΥΠΟΘΕΣΕΩΝ</button>
          <img onClick={handleLogout} src="/images/logout.svg" alt="Logout Icon" id={styles.logout}/>
        </form>
      </div>
    </div>
  );
}