import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './modules/LiveSessions.module.css';
import { supabase } from '../supabase';

export default function LiveSessions() {
  const courtTypes = [
    'ΜΟΝΟΜΕΛΕΣ ΠΛΗΜΜΕΛΕΙΟΔΙΚΕΙΟ',
    'Α ΤΡΙΜΕΛΕΣ ΠΛΗΜΜΕΛΕΙΟΔΙΚΕΙΟ',
    'Β ΤΡΙΜΕΛΕΣ ΠΛΗΜΜΕΛΕΙΟΔΙΚΕΙΟ',
    'ΜΟΝΟΜΕΛΕΣ ΕΦΕΤΕΙΟ ΚΑΚΟΥΡΓΗΜΑΤΩΝ',
    'ΤΡΙΜΕΛΕΣ ΕΦΕΤΕΙΟ ΚΑΚΟΥΡΓΗΜΑΤΩΝ',
    'ΤΡΙΜΕΛΕΣ ΕΦΕΤΕΙΟ ΠΛΗΜΜΕΛΗΜΑΤΩΝ',
    'ΜΙΚΤΟ ΟΡΚΩΤΟ ΔΙΚΑΣΤΗΡΙΟ',
    'ΜΙΚΤΟ ΟΡΚΩΤΟ ΕΦΕΤΕΙΟ',
  ];

  const [casesData, setCasesData] = useState({});
  // eslint-disable-next-line
  const [lastActiveCases, setLastActiveCases] = useState({});
  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  const [sessionsStatus, setSessionsStatus] = useState({});
  const [activeCases, setActiveCases] = useState({});
  const [completedSessions, setCompletedSessions] = useState({});
  const [activeSessionClicked, setActiveSessionClicked] = useState(false);

  const navigate = useNavigate(); 

  useEffect(() => {
    const fetchCases = async () => {
      const { data: sessionsData, error: sessionsError } = await supabase
        .from('sessions')
        .select('session_id, status');

      if (sessionsError) {
        console.error('Σφάλμα κατά την ανάκτηση sessions:', sessionsError);
        return;
      }

      const completedSessionsMap = {};
      sessionsData.forEach(session => {
        if (session.status === 'completed') {
          completedSessionsMap[session.session_id] = true;
        }
      });
      setCompletedSessions(completedSessionsMap);

      const sessionsStatusMap = sessionsData.reduce((acc, session) => {
        acc[session.session_id] = { status: session.status, room_name: '' };
        return acc;
      }, {});

      for (const session of sessionsData) {
        const { data: roomData, error: roomError } = await supabase
          .from('rooms')
          .select('room_name')
          .eq('current_session_id', session.session_id)
          .single();

        if (roomError) {
          console.error('Σφάλμα κατά την ανάκτηση room_name:', roomError);
          continue;
        }

        if (roomData) {
          sessionsStatusMap[session.session_id].room_name = roomData.room_name;
        }
      }

      setSessionsStatus(sessionsStatusMap);
      const { data: casesData, error: casesError } = await supabase
        .from('cases')
        .select('case_number, court_type, status, session_id')
        .in('status', ['active', 'paused', 'completed', 'invalidated']);

      if (casesError) {
        console.error('Σφάλμα κατά την ανάκτηση υποθέσεων:', casesError);
      } else {
        const initialActiveCases = {};
        const groupedCases = casesData.reduce((acc, curr) => {
          if (!acc[curr.court_type]) {
            acc[curr.court_type] = { active: null, paused: null, invalidated: null, sessionId: curr.session_id };
          }
          if (curr.status === 'active') {
            acc[curr.court_type].active = curr.case_number;
            initialActiveCases[curr.court_type] = curr.case_number;
            setActiveSessionClicked(true);
          } else if (curr.status === 'paused') {
            acc[curr.court_type].paused = curr.case_number;
          } else if (curr.status === 'invalidated') {
            acc[curr.court_type].invalidated = curr.case_number;
          }
          return acc;
        }, {});

        setCasesData(groupedCases);
        setActiveCases(initialActiveCases);

        setLastActiveCases(prevLastActive => {
          const newLastActive = { ...prevLastActive };
          Object.keys(groupedCases).forEach(courtType => {
            if (groupedCases[courtType].active) {
              newLastActive[courtType] = groupedCases[courtType].active;
            }
          });
          return newLastActive;
        });
      }
    };

    fetchCases();

    const subscription = supabase
      .channel('public:cases')
      .on('postgres_changes', { event: '*', schema: 'public', table: 'cases' }, payload => {
        updateCases(payload.new);
      })
      .subscribe();

    const sessionSubscription = supabase
      .channel('public:sessions')
      .on('postgres_changes', { event: '*', schema: 'public', table: 'sessions' }, payload => {
        if (payload.eventType === 'DELETE') {
          const deletedSessionId = payload.old.session_id;
          setCasesData(prevCases => {
            const updatedCases = { ...prevCases };
            Object.keys(updatedCases).forEach(courtType => {
              if (updatedCases[courtType].sessionId === deletedSessionId) {
                delete updatedCases[courtType];
              }
            });
            return updatedCases;
          });
          setCompletedSessions(prev => {
            const updated = { ...prev };
            delete updated[deletedSessionId];
            return updated;
          });
          setSessionsStatus(prev => {
            const updated = { ...prev };
            delete updated[deletedSessionId];
            return updated;
          });
        } else if (payload.eventType === 'UPDATE' && payload.new.status === 'completed') {
          setCompletedSessions(prev => ({ ...prev, [payload.new.session_id]: true }));
        }
      })
      .subscribe();

    const timer = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 1000);

    return () => {
      supabase.removeChannel(subscription);
      supabase.removeChannel(sessionSubscription);
      clearInterval(timer);
    };
  }, []);

  const updateCases = useCallback((newCase) => {
    setCasesData(prevCases => {
      const updatedCases = { ...prevCases };
      const courtType = newCase.court_type;

      updatedCases[courtType] = { 
        active: newCase.status === 'active' ? newCase.case_number : null,
        paused: newCase.status === 'paused' ? newCase.case_number : null,
        invalidated: newCase.status === 'invalidated' ? newCase.case_number : null,
        completed: newCase.status === 'completed' ? newCase.case_number : null,
        sessionId: newCase.session_id
      };

      return updatedCases;
    });

    if (newCase.status === 'active') {
      setActiveSessionClicked(true);
      setActiveCases(prevActiveCases => ({
        ...prevActiveCases,
        [newCase.court_type]: newCase.case_number
      }));
    } else if (newCase.status === 'completed') {
      setActiveCases(prevActiveCases => {
        const updatedActiveCases = { ...prevActiveCases };
        delete updatedActiveCases[newCase.court_type];
        return updatedActiveCases;
      });
    }
  }, []);

  const formatDateTime = (date) => {
    const days = ['ΚΥΡΙΑΚΗ', 'ΔΕΥΤΕΡΑ', 'ΤΡΙΤΗ', 'ΤΕΤΑΡΤΗ', 'ΠΕΜΠΤΗ', 'ΠΑΡΑΣΚΕΥΗ', 'ΣΑΒΒΑΤΟ'];
    const day = days[date.getDay()];
    const dateStr = date.toLocaleDateString('el-GR', { day: '2-digit', month: '2-digit', year: 'numeric' });
    const timeStr = date.toLocaleTimeString('el-GR', { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false });
    return `${day}, ${dateStr} / ${timeStr}`;
  };

  const memoizedCasesData = useMemo(() => casesData, [casesData]);

  const getBorderClass = (isSessionCompleted, isInvalidated, isPaused, isActive) => {
    if (isSessionCompleted) return styles.completedBorder;
    if (isInvalidated) return styles.invalidatedBorder;
    if (isPaused) return styles.pausedBorder;
    if (isActive) return styles.activeBorder;
    return '';
  };

  const handleRoomClick = (roomName) => {
    const roomNumber = roomName.replace('ΑΙΘΟΥΣΑ ', '');
    navigate(`/room/${roomNumber}`);
  };

  const noCasesAvailable = Object.keys(memoizedCasesData).length === 0;

  return (
    <div className={styles.container}>
      <h1 className={styles.header}>ΗΜΕΡΗΣΙΑ ΠΙΝΑΚΙΑ</h1>
      <p className={styles.dateTime}>{formatDateTime(currentDateTime)}</p>
      <h3 className={styles.heading}>ΤΡΕΧΟΥΣΕΣ ΥΠΟΘΕΣΕΙΣ</h3>
      {activeSessionClicked && !noCasesAvailable ? (
        <div className={styles.bordersContainer}>
          {courtTypes.map((courtType, index) => {
            const courtData = memoizedCasesData[courtType];
            if (courtData) {
              const isActive = courtData.active !== null;
              const isPaused = !isActive && courtData.paused !== null;
              const isInvalidated = !isActive && !isPaused && courtData.invalidated !== null;
              const isCompleted = !isActive && !isPaused && !isInvalidated && courtData.completed !== null;
              const sessionInfo = sessionsStatus[courtData.sessionId];
              const isSessionCompleted = completedSessions[courtData.sessionId];
              const roomName = sessionInfo?.room_name; 

              const displayNumber = isActive
                ? courtData.active
                : isPaused
                ? activeCases[courtType] || courtData.paused
                : isInvalidated
                ? courtData.invalidated
                : isCompleted
                ? courtData.completed
                : null;

              const borderClass = getBorderClass(isSessionCompleted, isInvalidated, isPaused, isActive);

              if (displayNumber) {
                return (
                  <div
                    key={index}
                    className={`${styles.border} ${borderClass}`}
                    onClick={() => handleRoomClick(roomName)} 
                    style={{ cursor: 'pointer' }} 
                  >
                    <h4 className={styles.courtType}>{courtType}</h4>
                    {isSessionCompleted ? (
                      <p className={styles.completedMessage}>ΟΛΟΚΛΗΡΩΣΗ ΗΜΕΡΑΣ</p>
                    ) : (
                      <p className={styles.caseNumber}>{displayNumber}</p>
                    )}
                  </div>
                );
              }
            }
            return null;
          })}
        </div>
      ) : (
        <p className={styles.noCasesMessage}>Δεν υπάρχουν live συνεδρίες</p>
      )}
    </div>
  );
}